import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="POS" />

    <div id="wrapper">
      <h1>POS demo with RFID payment</h1>
      <div style={{ textAlign: 'center' }}>
        <img src="/images/pos.png" alt="Screenshot" style={{ marginTop: 20, width: '70%', maxWidth: 800 }} />
      </div>
    </div>
  </Layout>
);
